<template>
  <div id="BannerManage" class="section">
    <div class="columns is-multiline">
      <div class="column is-10-desktop is-10-tablet is-12-mobile">
        <h1 class="title">
          BANNER MANAGER
        </h1>
      </div>
      <div class="column is-12-mobile">
        <b-button
          expanded
          type="is-primary"
          icon-left="plus"
          @click.prevent="navigateToBannerDetailPage"
        >
          Add Banner
        </b-button>
      </div>
    </div>

    <div class="container">
      <banner-table />
    </div>
  </div>
</template>

<script>
import BannerTable from "@/components/tables/BannerTable";

export default {
  name: "BannerManage",
  components: {
    BannerTable
  },
  methods: {
    navigateToBannerDetailPage: function() {
      this.$router.push({ name: "banner-detail" });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#BannerManage {
  text-align: left;
}
</style>
