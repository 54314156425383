import MainConnection from "./MainConnection";
import AlertManager from "../utils/AlertManager";

export default {
  requestBannerList: async function() {
    try {
      const requestURL = "/banners";
      const response = await MainConnection.request().get(requestURL);

      switch (response.data.code) {
        case "0":
          return response.data.data;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          break;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestCreateBanner: async function(params) {
    try {
      const requestURL = "/banners";
      const response = await MainConnection.request().post(requestURL, params);

      switch (response.data.code) {
        case "0":
          return response.data.data;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          break;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestUpdateBanner: async function(banner_id, params) {
    try {
      const requestURL = "/banners/" + banner_id;
      const response = await MainConnection.request().patch(requestURL, params);

      switch (response.data.code) {
        case "0":
          return response.data.data;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          break;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  removeBannerById: async function(banner_id) {
    try {
      const requestURL = "/banners/" + banner_id;
      const response = await MainConnection.request().delete(requestURL);

      switch (response.data.code) {
        case "0":
          return true;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          return false;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  }
};
